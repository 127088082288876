<template>
  <div>
    <!-- Controllers -->
    <div class="d-flex justify-end py-4 pr-6">
      <v-btn color="primary" class="mr-3" dark depressed @click="update()" v-text="$t('common.save')" />
    </div>
    <!-- Body -->
    <v-container class="px-6 pt-0 pb-6 bigScreenForm">
      <v-row>
        <v-col md="8" sm="12">
          <v-row>
            <v-col cols="12">
              <h2 class="mb-4" v-text="$t('project.editProject.generalInformation')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.name"
                :error-messages="getFieldErrors('name')" :label="$t('common.name')" outlined dense @blur="$v.form.name.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-textarea
                v-model="form.cardDescription"
                :placeholder="$t('project.createProjectDialog.cardDescription')" :error-messages="getFieldErrors('cardDescription')"
                outlined dense @blur="$v.form.cardDescription.$touch()"
              />
            </v-col>
          </v-row>
          <v-row class="mt-16">
            <v-col cols="12" sm="6" class="py-1 px-3">
              <div class="mb-4 d-flex">
                <h3 class="mr-3" v-text="$t('common.logo')" />
                <global-tooltip
                  :text="$t('common.tooltipImageSizes', {
                    proportion: '1:1',
                    width: '200px',
                    height: '200px'
                  })"
                />
              </div>
              <image-field
                v-model="form.logo"
                :error-messages="getFieldErrors('logo')"
                :width="200" :height="200"
                suffix="_logo" @input="$v.form.logo.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <div class="mb-4 d-flex">
                <h3 class="mr-3" v-text="$t('project.editProject.banner')" />
                <global-tooltip
                  :text="$t('common.tooltipImageSizes', {
                    proportion: '16:9',
                    width: '640px',
                    height: '360px'
                  })"
                />
              </div>
              <image-field
                v-model="form.banner"
                :error-messages="getFieldErrors('banner')"
                :width="640" :height="360"
                suffix="_banner" @input="$v.form.banner.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="isGuild" class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('cluster.dataFederation')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-autocomplete
                v-model="form.timezone"
                :error-messages="getFieldErrors('timezone')" :label="$t('common.timeZone')" :items="timezoneItems"
                outlined dense class="mb-1" @blur="$v.form.timezone.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <date-range-field
                v-model="form.foundationDate" :is-single-date="true" :label="`${$t('cluster.foundationDate')} ${$t('common.optional')}`"
                :timezone="form.timezone" @input="newValue => form.foundationDate = newValue"
                @blur="$v.form.foundationDate.$touch()" @resetDate="form.foundationDate = null"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <date-range-field
                v-model="form.lastElectionDate"
                :is-single-date="true" :label="`${$t('cluster.lastElectionDate')} ${$t('common.optional')}`"
                :timezone="form.timezone" @input="newValue => form.lastElectionDate = newValue"
                @blur="$v.form.lastElectionDate.$touch()" @resetDate="form.lastElectionDate = null"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <date-range-field
                v-model="form.dateLastMeeting"
                :is-single-date="true" :label="`${$t('cluster.dateLastMeeting')} ${$t('common.optional')}`"
                :timezone="form.timezone" @input="newValue => form.dateLastMeeting = newValue"
                @blur="$v.form.dateLastMeeting.$touch()" @resetDate="form.dateLastMeeting = null"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.ministryRegistrationNumber"
                :label="`${$t('cluster.ministryRegistrationNumber')} ${$t('common.optional')}`" outlined dense
                @blur="$v.form.ministryRegistrationNumber.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.legalEntityId"
                :label="`${$t('cluster.legalEntityId')} ${$t('common.optional')}`" outlined dense @blur="$v.form.legalEntityId.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.address.line1"
                :label="`${$t('cluster.address')} ${$t('common.optional')}`" outlined dense @blur="$v.form.address.line1.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-select
                v-model="form.address.country" :items="countryItems"
                :label="`${$t('user.fields.country')} ${$t('common.optional')}`" outlined dense append-icon="mdi-chevron-down"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.region"
                :label="`${$t('cluster.region')} ${$t('common.optional')}`" outlined dense @blur="$v.form.address.region.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.city"
                :label="`${$t('common.city')} ${$t('common.optional')}`" outlined dense @blur="$v.form.address.city.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.zip"
                :label="`${$t('common.postalCode')} ${$t('common.optional')}`" outlined dense @blur="$v.form.address.zip.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.website"
                :error-messages="getFieldErrors('website')" :label="`${$t('cluster.website')} ${$t('common.optional')}`"
                outlined dense @blur="$v.form.website.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.email"
                :error-messages="getFieldErrors('email')" :label="`${$t('user.fields.email')} ${$t('common.optional')}`"
                outlined dense @blur="$v.form.email.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.twitter"
                :label="`${$t('common.twitter')} ${$t('common.optional')}`" outlined dense @blur="$v.form.twitter.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.facebook"
                :label="`${$t('common.facebook')} ${$t('common.optional')}`" outlined dense @blur="$v.form.facebook.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model.number="form.numberRegisteredGuilds" type="number" min="1" :label="`${$t('cluster.numberRegisteredGuilds')} ${$t('common.optional')}`"
                outlined dense @keypress="validateKeyNumeric($event)" @blur="$v.form.numberRegisteredGuilds.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model.number="form.numberPlayersGuildApproximate"
                type="number" min="1" :label="`${$t('cluster.numberPlayersGuildApproximate')} ${$t('common.optional')}`"
                outlined dense @keypress="validateKeyNumeric($event)" @blur="$v.form.numberPlayersGuildApproximate.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-switch
                v-model="form.hasAgreementClub"
                :label="$t('cluster.hasAgreementClub')" @change="$v.form.hasAgreementClub.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="isGuild" class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('cluster.dataPresident')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <phone-field
                v-model="form.phonePresident" outlined dense
                @blur="$v.form.phonePresident.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.emailPresident"
                :error-messages="getFieldErrors('emailPresident')" :label="$t('user.fields.email')" outlined dense @blur="$v.form.emailPresident.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="isGuild" class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('cluster.dataSecretary')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <phone-field
                v-model="form.phoneSecretary" outlined dense
                @blur="$v.form.phoneSecretary.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.emailSecretary"
                :error-messages="getFieldErrors('emailSecretary')" :label="$t('user.fields.email')" outlined dense @blur="$v.form.emailSecretary.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="isGuild" class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('cluster.dataChargeCommunication')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <phone-field
                v-model="form.phoneChargeCommunication" outlined dense
                @blur="$v.form.phoneChargeCommunication.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.emailChargeCommunication"
                :error-messages="getFieldErrors('emailChargeCommunication')" :label="$t('user.fields.email')"
                outlined dense @blur="$v.form.emailChargeCommunication.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="isGuild" class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('cluster.dataChargeApp')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <phone-field
                v-model="form.phoneChargeApp" outlined dense
                @blur="$v.form.phoneChargeApp.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.emailChargeApp"
                :error-messages="getFieldErrors('emailChargeApp')" :label="$t('user.fields.email')"
                outlined dense @blur="$v.form.emailChargeApp.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="isGuild" class="mt-8">
            <v-col cols="12" class="py-1 px-3">
              <h2 class="mb-4" v-text="$t('common.observations')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.numberRegistrationAficionesUnidas"
                :label="$t('cluster.numberRegistrationAficionesUnidas')" outlined dense @blur="$v.form.numberRegistrationAficionesUnidas.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.clubWhichIsFederation"
                :label="$t('cluster.clubWhichIsFederation')" outlined dense @blur="$v.form.clubWhichIsFederation.$touch()"
              />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-text-field
                v-model="form.accountNumber"
                :label="$t('cluster.accountNumber')" outlined dense @blur="$v.form.accountNumber.$touch()"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { url, email, required, requiredIf, numeric } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import saveDialogUtils from '@/utils/mixins/saveDialog'
import i18nCountries from '@/utils/mixins/i18nCountries'
import timezonesEnum from '@/enums/timezonesEnum'

export default {
  name: 'EditCluster',
  components: {
    ImageField: () => import('@/components/formFields/ImageField'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
    DateRangeField: () => import('@/components/formFields/DateRangeField'),
    PhoneField: () => import('@/components/formFields/PhoneField'),
  },
  mixins: [formUtils, saveDialogUtils, i18nCountries],
  props: {
    organizationId: { type: String, required: true },
    clusterId: { type: String, required: true },
  },
  data: () => ({ form: {}, timezoneItems: timezonesEnum.map(v => ({ text: v, value: v })) }),
  computed: {
    ...mapGetters({ cluster: 'cluster/data' }),
    ...mapGetters('organization', ['isGuild']),
  },
  validations() {
    return {
      form: {
        name: { required },
        cardDescription: { required },
        logo: { required },
        banner: { required },
        ...(this.isGuild && { timezone: { required: requiredIf(() => this.form.foundationDate || this.form.lastElectionDate) } }),
        ...(this.isGuild && { foundationDate: {} }),
        ...(this.isGuild && { lastElectionDate: {} }),
        ...(this.isGuild && { dateLastMeeting: {} }),
        ...(this.isGuild && { ministryRegistrationNumber: {} }),
        ...(this.isGuild && { legalEntityId: {} }),
        ...(this.isGuild && { address: { line1: {}, country: {}, region: {}, city: {}, zip: {} } }),
        ...(this.isGuild && { website: { url } }),
        ...(this.isGuild && { email: { email } }),
        ...(this.isGuild && { twitter: {} }),
        ...(this.isGuild && { facebook: {} }),
        ...(this.isGuild && { numberRegisteredGuilds: { numeric } }),
        ...(this.isGuild && { numberPlayersGuildApproximate: { numeric } }),
        ...(this.isGuild && { hasAgreementClub: {} }),
        ...(this.isGuild && { phonePresident: { code: { }, number: { } } }),
        ...(this.isGuild && { emailPresident: { email } }),
        ...(this.isGuild && { phoneSecretary: { code: { }, number: { } } }),
        ...(this.isGuild && { emailSecretary: { email } }),
        ...(this.isGuild && { phoneChargeCommunication: { code: { }, number: { } } }),
        ...(this.isGuild && { emailChargeCommunication: { email } }),
        ...(this.isGuild && { phoneChargeApp: { code: { }, number: { } } }),
        ...(this.isGuild && { emailChargeApp: { email } }),
        ...(this.isGuild && { numberRegistrationAficionesUnidas: {} }),
        ...(this.isGuild && { clubWhichIsFederation: {} }),
        ...(this.isGuild && { accountNumber: {} }),
      },
    }
  },
  created() {
    this.form = {
      name: this.cluster.name,
      cardDescription: this.cluster.cardDescription ?? null,
      logo: this.cluster.logo ?? null,
      banner: this.cluster.banner ?? null,
      ...(this.isGuild && { timezone: this.cluster.timezone ?? null }),
      ...(this.isGuild && { foundationDate: this.cluster.foundationDate ?? null }),
      ...(this.isGuild && { lastElectionDate: this.cluster.lastElectionDate ?? null }),
      ...(this.isGuild && { dateLastMeeting: this.cluster.dateLastMeeting ?? null }),
      ...(this.isGuild && { ministryRegistrationNumber: this.cluster.ministryRegistrationNumber ?? null }),
      ...(this.isGuild && { legalEntityId: this.cluster.legalEntityId ?? null }),
      ...(this.isGuild && {
        address: this.cluster.address ?? {
          address: {
            line1: this.cluster.address?.line1 ?? null,
            zip: this.cluster.address?.zip ?? null,
            city: this.cluster.address?.city ?? null,
            region: this.cluster.address?.region ?? null,
            country: this.cluster.address?.country ?? null,
          },
        },
      }),
      ...(this.isGuild && { website: this.cluster.website ?? null }),
      ...(this.isGuild && { email: this.cluster.email ?? null }),
      ...(this.isGuild && { twitter: this.cluster.twitter ?? null }),
      ...(this.isGuild && { facebook: this.cluster.facebook ?? null }),
      ...(this.isGuild && { numberRegisteredGuilds: this.cluster.numberRegisteredGuilds ?? null }),
      ...(this.isGuild && { numberPlayersGuildApproximate: this.cluster.numberPlayersGuildApproximate ?? null }),
      ...(this.isGuild && { hasAgreementClub: this.cluster.hasAgreementClub ?? false }),
      ...(this.isGuild && {
        phonePresident: this.cluster.phonePresident
      ?? { phonePresident: { country: null, code: null, number: null } },
      }),
      ...(this.isGuild && { emailPresident: this.cluster.emailPresident ?? null }),
      ...(this.isGuild && {
        phoneSecretary: this.cluster.phoneSecretary
      ?? { phoneSecretary: { country: null, code: null, number: null } },
      }),
      ...(this.isGuild && { emailSecretary: this.cluster.emailSecretary ?? null }),
      ...(this.isGuild && {
        phoneChargeCommunication: this.cluster.phoneChargeCommunication
      ?? { phoneChargeCommunication: { country: null, code: null, number: null } },
      }),
      ...(this.isGuild && { emailChargeCommunication: this.cluster.emailChargeCommunication ?? null }),
      ...(this.isGuild && {
        phoneChargeApp: this.cluster.phoneChargeApp
      ?? { phoneChargeApp: { country: null, code: null, number: null } },
      }),
      ...(this.isGuild && { emailChargeApp: this.cluster.emailChargeApp ?? null }),
      ...(this.isGuild && { numberRegistrationAficionesUnidas: this.cluster.numberRegistrationAficionesUnidas ?? null }),
      ...(this.isGuild && { clubWhichIsFederation: this.cluster.clubWhichIsFederation ?? null }),
      ...(this.isGuild && { accountNumber: this.cluster.accountNumber ?? null }),
    }
  },
  methods: {
    async update() {
      if (!this.isFormValid()) {
        this.scrollToFirstError()
        return
      }
      const { organizationId, clusterId } = this
      await this.runAsync(() => this.$store.dispatch('cluster/update', { organizationId, clusterId, data: this.form }))
      this.userAgreedToLeave = true
    },
  },
}
</script>
